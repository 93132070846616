import React from 'react';

const Logo = (props) => {
  return <svg
    xmlns="http://www.w3.org/2000/svg"
    width={195.667}
    height={23.626}
    viewBox="0 0 195.667 23.626"
    {...props}
  >
    <path
      fill='#ffffff'
      d="M11.348 4.263v19.363H6.921V4.263H.188V.298H18.32v3.965h-6.972zM36.355 19.149H26.29l-1.453 4.478h-4.683L27.913.299h6.648l7.878 23.328h-4.597l-1.487-4.478zm-1.025-3.265l-4.033-12.15-3.965 12.15h7.998zM65.789.298l-7.007 11.297 7.468 12.031h-5.195l-3.35-5.605a58.422 58.422 0 01-1.47-2.632l-.479-.923-.496-.906h-.068l-.496.924a72.452 72.452 0 01-2.017 3.537l-3.367 5.605H43.98l7.656-12.031L44.529.298h5.281l3.11 5.195c.49.82.951 1.63 1.384 2.428l.427.82.427.82h.068c.193-.365.336-.639.427-.82l.427-.804c.364-.706.82-1.515 1.367-2.427l3.11-5.213h5.232zM79.552.093c4.238 0 6.967.704 8.186 2.111 1.219 1.407 1.829 4.555 1.829 9.441 0 5.344-.613 8.711-1.837 10.101-1.225 1.391-4.19 2.085-8.896 2.085-4.238 0-6.979-.687-8.22-2.06-1.242-1.372-1.863-4.395-1.863-9.065 0-5.561.609-9.052 1.829-10.477C71.798.806 74.789.093 79.552.093zm-.342 3.795c-2.974 0-4.711.382-5.212 1.145-.501.764-.752 3.407-.752 7.93 0 3.35.293 5.366.88 6.05.586.684 2.315 1.025 5.187 1.025 2.746 0 4.392-.385 4.939-1.153.547-.77.82-3.102.82-6.998 0-3.908-.259-6.218-.778-6.931-.519-.712-2.213-1.068-5.084-1.068z"
    />
    <path
      fill="#54B2CF"
      d="M109.567 12.381h3.793v.752l-.033 2.034c0 .979-.143 2.022-.428 3.127l3.35 2.837-2.102 2.546-2.803-2.357c-1.037 1.674-3.43 2.512-7.178 2.512-4.169 0-6.902-.479-8.195-1.436s-1.939-2.984-1.939-6.084c0-3.497 1.48-5.463 4.443-5.896-1.276-1.116-1.914-2.518-1.914-4.203 0-2.154.526-3.641 1.581-4.461 1.054-.82 2.959-1.23 5.715-1.23 2.895 0 4.869.419 5.922 1.256 1.055.838 1.582 2.413 1.582 4.726l-.018 1.214h-3.932v-.685c0-1.402-.189-2.282-.572-2.641-.381-.359-1.313-.539-2.793-.539-2.188 0-3.282.815-3.282 2.444 0 1.105.569 2.143 1.709 3.11l7.092 6.084c.113-.592.17-1.145.17-1.658a7.13 7.13 0 00-.168-1.452zm-8.75.085c-1.721.251-2.581 1.539-2.581 3.863 0 1.8.29 2.945.872 3.435.58.49 1.937.735 4.066.735 1.836 0 3.023-.083 3.564-.248s1.119-.63 1.734-1.393l-7.655-6.392z"
    />
    <path
      fill='#ffffff'
      d="M147.477.298v23.328h-4.426V10.912c0-1.014.027-2.164.086-3.452l.084-1.743.086-1.727h-.137l-.529 1.624-.514 1.624a89.734 89.734 0 01-1.109 3.229l-5.127 13.159h-4.033l-5.18-13.057c-.283-.718-.66-1.795-1.127-3.23l-.529-1.623-.531-1.606h-.137l.086 1.692.086 1.709c.068 1.321.102 2.455.102 3.4v12.715h-4.426V.298h7.213l4.17 10.801c.283.752.66 1.829 1.127 3.23l.514 1.623.529 1.607h.154l.494-1.607.514-1.605c.422-1.356.785-2.428 1.094-3.213L140.113.298h7.364zM167.375 19.149h-10.066l-1.453 4.478h-4.682L158.932.299h6.648l7.879 23.328h-4.598l-1.486-4.478zm-1.025-3.265l-4.033-12.15-3.965 12.15h7.998zM177.155 23.626V.298H187.717c3.154 0 5.283.551 6.383 1.649 1.1 1.1 1.648 3.222 1.648 6.366 0 3.122-.523 5.195-1.572 6.221s-3.168 1.538-6.357 1.538l-1.025.017h-5.213v7.537h-4.426zm4.426-11.261h5.043c2.004 0 3.277-.231 3.818-.692.541-.462.813-1.553.813-3.272 0-1.972-.225-3.193-.676-3.666-.449-.473-1.621-.709-3.512-.709h-5.486v8.339z"
    />
  </svg>;
};

export default Logo;